import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 5×2\\@90%1RM`}</p>
    <p>{`then,`}</p>
    <p>{`42-Wall Balls (20/14)(RX+ 30/20)`}</p>
    <p>{`21-Deadlifts (225/155)(RX+ 275/185)`}</p>
    <p>{`42-Pushups`}</p>
    <p>{`30-Wall Balls`}</p>
    <p>{`15-Deadlifts`}</p>
    <p>{`30-Pushups`}</p>
    <p>{`18-Wall Balls`}</p>
    <p>{`9-Deadlifts`}</p>
    <p>{`18-Pushups`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      